/* Entire background image */
.ActiveImageBackgroundCarousel {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 1000ms opacity ease-in-out;
}

.InactiveImageBackgroundCarousel {
    /* display: none; */
    opacity: 0;
    transition: 1000ms opacity ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
}

.imageCarouselArray {
    min-width: 1300px;
    min-height: 1000px;
    width: 100%;
    height: 100%;
    transition: 1000ms opacity ease-in-out;
    opacity: 1;
    transition-delay: 0;

}



/* description section */

.ActiveImageCaptionsCarousel {
    z-index: 2;
    width: 100%;
    /* height: 100%; */
    min-width: 1300px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    position: relative;
    top: -230px;
    opacity: 1;
    transition: 1000ms opacity ease-in-out;
}

.InactiveImageCaptionsCarousel {
    z-index: 2;
    min-width: 1300px;
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    position: relative;
    top: -230px;
    transition: 1000ms opacity ease-in-out;
    opacity: 0;
}

.background{
    height: 100%;
}

.BackgroundImageCarouselTitle {
    font-size: 36px;
    color: aliceblue;
    font-weight: bold;
    text-shadow: 2px 2px 10px black
}
.BackgroundImageCarouselAuthor {
    font-size: 28px;
    color: rgb(224, 224, 224);
    font-weight: 600;
    text-shadow: 1px 1px 5px black


}

.ParentofCarouselCaptions {
    height: 100%;
    min-height: 1000px;
    width: 100%;
    min-width: 1300px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
