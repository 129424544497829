.edit-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
}

.Edit-image-inner{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
    padding: 50px;
}

.done-edit-container {
    display: flex;
    justify-content: center;
    column-gap: 10px;
}
