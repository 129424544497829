.profile-container {
    background: rgba(235, 235, 235, 0.99);
    display: flex;
    flex-direction: column;
    height:100%;
}

.cover-photo-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cover-photo {
    width: 100%;
    height: 30em;
    margin-top: -170px;
    z-index: 1;
    position: relative;
}

.user-title-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    z-index: 2;
    margin-top: -7.3em;
}

.profile-pic-name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-right: 16px;
}

.user-fName-lName-username{
    display: flex;
    flex-direction: column;
}

.username-joined{
    display: flex;
    justify-content: left;
}

.joined {
    margin-left: 40em;
}

.user-showcase-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    background-color: rgba(235, 235, 235, 0.99);
}

.user-showcase-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(203, 203, 203);
    background-color: white;
    width: 70em;
    height: 100%;
    margin-top: 30px;
}

.showcase-title{
    margin-right: 55em;
}

.user-photos-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    height: fit-content;
    width: fit-content;
    width: 60em;
    padding: 15px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.UserShowcaseInnerContainer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    column-gap: 30px;

}
.explore-image-bttm-section {
    display: none;
}

.user-singleImgContainer:hover .explore-image-bttm-section {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    position: absolute;
    margin-top: -4rem;
    z-index: 2;
    background: linear-gradient(to top, rgba(0, 0, 0), rgba(255, 255, 255, 0));
    flex-wrap: nowrap;
}

.user-single-img {
    max-height: 200px;
    width: 100%;
    margin-right: 10px;
}

.user-singleImgContainer:hover {
    transform: scale(1.1);
    transition: all .3s ease-in-out;
}

.hide {
    width:100%;
    margin-left: 5px;
}

.user-photo-details-container{
    display: flex;
    justify-content: center;
    border-top: 1px solid grey;
    width: 70em;
}

.user-photo-details{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-right: 43em;
}

.user-photo-details-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
    margin-bottom: 30px;
}

.user-info-container{
    display: flex;
    flex-direction: column;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    width: 70em;
}

.user-info{
    display: flex;
    justify-content: left;
    margin-top: 30px;
    margin-left: 7em;
    color: grey;
    font-size: 16px;
}

.user-joined{
    color: black;
    margin-top: 3px;
    margin-left: 7em;
}

.user-email{
    color: black;
    margin-left: 7em;
    margin-bottom: 5em;
}
