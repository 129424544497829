.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  background-color: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
}

.nav a {
  text-decoration: none;
  padding: 4px;
}

.logo {
  height: 25px;
  width: 100px;
  margin-left: 20px;
  padding-left: 15px;
}

.search-bar {
  flex: 1;
  width: 50rem;
  height: 35px;
  border-radius: 3px;
  border: solid white 0;
  opacity: .8;
}

.search-bar::placeholder {
  padding-left: 40px;
}

a.log-in {

  font-size: 11pt;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  /* font-weight: 600; */
  margin: 0 5px;
  width: 60px;
  padding: 10px 0;
  /* box-shadow: 0 0 20px rgba(104, 85, 224, 0.2); */
  transition: 0.4s;

}

a.sign-up {
  font-size: 11pt;
  font-weight: bold;
  color: black;
  background-color: white;

  border-radius: 3px;
  padding: 7px 20px;
  margin-right: 30px;
  /* margin-left: 10px; */
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  font-weight: 600;
  width: 60px;
  /* padding: 10px 0; */
  box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
  transition: 0.4s;

}

a.sign-up:hover {
  transition: color .15s ease-in-out;
  color: gray;
}



.center {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: 340px;
}

.main {
  color: #fff;
  display: grid;
  height: 300px;
  justify-items: center;
  /* margin-top: 100px; */
  max-width: 700px;
  position: absolute;
  text-align: center;
}

h1 {
  font-size: 52px;
  font-weight: 550;
}

h2 {
  display: block;
  font-size: 26px;
  font-weight: bold;

  position: relative;
  bottom: 25px;
}

h3 {
  display: inline-block;
  font-size: 24px;
  background-color: #fff;
  color: black;
  font-weight: bold;
  line-height: 64px;

  vertical-align: top;
  border-radius: 3px;
  padding: 0 30px;

  position: relative;
  bottom: 15px;
}

h3 {
  color: black;
}

#for-free, h3:hover {
  transition: color .15s ease-in-out;
  color: gray
}

a {
  text-decoration: none;
}

.parentFooter {
  height: 100%;
  min-height: 1000px;
  width: 100%;
  min-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

.footer-home {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: rgb(0, 0, 0);
  color: #fff;
  font-size: 10pt;

  height: 50px;
  width: 100%;

  position: relative;
  top: 0;
  bottom: 100px;
  left: 0;
  right: 0;
}

.ourRepo{
  background-color: black;
  height:16px;
  width:16px;
  float:right;
  color:white;
  text-decoration: underline;
  padding-right: 4px;

}

.text-for-github{

  font-size: 10pt;
  text-decoration: underline;
  color: white;
}

.inspiration {
  color: white;
  text-shadow: 1px 1px 5px black
}

.first-line {
  text-shadow: 1px 1px 5px black
}
@media screen and (max-width: 770px) {
  .search-bar {
      display: none;
  }
}
