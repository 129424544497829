.DeleteComment-outer {
    display: flex;
    justify-content: center;
    height: 10rem;
    width: 24rem;
}

#statement, #assurance {
    font-weight: 300;
}

.deleteTagButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.deleteTagButtons button.submitDeleteComment {
    color: white;
    background-color: #348fdc;
    padding: .5rem 1rem;
    margin-top: .5rem;
    border-radius: .3rem;
    border: none;
    font-size: 1rem;
    width: 7rem
}

.deleteTagButtons button.cancelDeleteComment {
    color: white;
    background-color: #979ea2;
    padding: .5rem 1rem;
    margin-top: .5rem;
    border-radius: .3rem;
    border: none;
    font-size: 1rem;
    width: 7rem
}

.submitDeleteComment:hover{
    opacity: .8;
    transform: scale(1.1);
    cursor: pointer;
}

.cancelDeleteComment:hover {
    opacity: .8;
    transform: scale(1.1);
    cursor: pointer;
}

.submitDeleteComment:active{
    transform: scale(.98);
    cursor: pointer;
}

.cancelDeleteComment:active{
    transform: scale(.98);
    cursor: pointer;
}
