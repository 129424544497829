.explore-container {
    background: white;
    display:flex;
    justify-content: center;
    margin-bottom: 10em;
}

.images-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;

}
.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    /* new */
    justify-content: space-between;
    gap: 10px;
    /* new */

    width: 75rem;
    max-width: 80rem;
    padding: 15px;
    margin-top: -3rem;
    box-sizing: content-box;
    width: 100%;
    margin-bottom: 23em;
}

    /* new */

.images-wrapper > * {
    flex: 1 1 auto;
}



.singleImgContainer {
    width: auto;
}

    /* new */


.single-img {
    max-height:220px;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-right: 5px;
    margin-top: 5px;
    z-index: 1;
}

.singleImgContainer:hover {
    transform: scale(1.1);
    transition: all .3s ease-in-out;
}

.hide {
    width:100%;
    margin-left: 5px;
}

.explore-title {
    font-size: 22px;
    font-weight: 300;
    padding-top: 20px;
    padding-left:20px;

}

#star-like i {
    font-size: 1.5rem;
    margin: 0 .8rem;
}

#star-like i:hover {
    opacity: .8;
    cursor: pointer;
}
#star-like i {
    color: rgb(18, 16, 16);
}

.explore-image-bttm-section {
    display: none;
}

.singleImgContainer:hover .explore-image-bttm-section {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    position: absolute;
    margin-top: -4rem;
    z-index: 2;
    background: linear-gradient(to top, rgba(0, 0, 0), rgba(255, 255, 255, 0));
    flex-wrap: nowrap;
}

.hide, .image-likes-section {
    margin-top: 29px;
    color: white;
    width: 100%;
}

#star-like i {
    color: rgb(189, 34, 34);
    height: 20px
}

#star-icon-explore{

    /* z-index: 2; */
    height:10px;
    width:20px;
}

.image-likes-container{
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
    flex-direction: row;
    width:100%;
    height:100%;
    margin-right: 5px;
}
.image-likes-section{
    display: flex;
    column-gap: 2px;
    justify-content: flex-end;
    flex-direction: row;
    width:fit-content;
    height:100%;
}


/* comments modal */



.hiddenComment {
    display: none;
}


.ExitCommentsSection{
    display: flex;
    column-gap: 2px;
    justify-content: flex-end;
    flex-direction: row;
    width:fit-content;
    height:100%;
    margin-top: 1.82rem;
    color: tomato;
    width: 100%;
}

.flexRow {
    display: flex;
    flex-direction: row;
}
