.CommentContainer {

}

.userContainerforExploreModal {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

}

.userfullnameExploreCommentsModal {
    font-weight: bold;
    color: purple;
}

.userContainerExploreEditDelete {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 5px;
    width: fit-content;
}

.ExplorePageTopHalfCommentsBox {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* padding: 10px; */
}

.CommentsBoxExploreContents {
    width: 240px;
    height: 300px;
    top: 5px;
    left: -230px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    border-radius: 5%;
    color: black;
    z-index: 2;
}

.CommentBodyContainerExplorePage {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 100%;
}

.allCommentsContainerExplorePage {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    column-gap: 10px;
    width: 100%;
    height: 100%;
}

.CommentsContainerUserProfilePicture {
 height: 50px;
 width: 50px;
 border-radius: 100px;
}

.ProfilePictureCommentsContainerExplore {
    width: 50px;
    height: 100%;
}

.CommentOverallExploreContainer {
    padding-right: 10px;
    /* display: flex;
    flex-direction: row;
    width: 100%; */
}


.TextBoxContainerinExploreUpper {
width: 100%;
overflow-x: hidden;
}


.textareacommentbox{
    height: 3rem;
}


.BottomPartExplorePageCommentBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    height: 100%;
}

.ProfilePictureCommentsContainerExploreBottom {
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CommentsContainerUserProfilePictureBottom {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    position: relative;
    bottom: 20px;
   }

.BottomPartExplorePageCommentBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

#closeCommentSectionButton {
    font-size: 1.5em; color: Tomato;
    width: 100%;
    text-align: left;
}
