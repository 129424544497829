/* body {
    background: url(https://images.unsplash.com/photo-1541417904950-b855846fe074?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1141&q=80);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
} */

body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.login-container {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
}

.inner-login {
    display: flex;
    align-items: center;
    border: solid white;
    border-radius: .3rem;
    justify-items: center;
    background-color: white;
}

.login-form {
    display: grid;
    justify-items: center;
    width: 22rem;

}

#login-banner {
    display: grid;
    justify-content: space-around;
}

#circles-container {
    display: flex;
    justify-content: center;

}

#circles {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: .8rem;
    padding-bottom: .7rem;
}

#to-klickr {
    font-size: 1.3rem;
    font-weight: 400;
    margin: .5rem 0;
}

.label-input {
    display: flex;
    justify-content: center;
}

.label-input label {
    font-size: .75rem;
    position: absolute;
    margin-top: 1.3rem;
    margin-right: 13rem;
    opacity: .6;
}

#password {
    margin-right: 14.5rem;
}

.label-input input {
    width: 18rem;
    height: 3.5rem;
    margin: 1rem;
    border-radius: .3rem;
    font-size: 1rem;
    border: solid .1rem black;
}

.label-input:focus {
    border-color: #348fdc;
    color:#348fdc;
}

.button {
    display: flex;
    margin: .5rem 0;
    margin-bottom: 1rem;
}

.button button {
    height: 2.5rem;
    width: 18.5rem;
    color: white;
    background-color: #348fdc;
    border-radius: .3rem;
    border: solid 0;
    font-size: 1rem;
    font-weight: bold;
}

.button button:hover {
    opacity: .9;
    cursor: pointer;
}

/* name headers displaced */
#labelInputSignUpName {
    margin-left: -18px;
}

.login-errors{
    color: red;
    font-size: 1rem;
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
