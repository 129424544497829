.edit-image-container{
    display: flex;
    flex-direction: column;
}
.edit-image-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
}

.EditImageHeader{
    font-weight: 300;
}

.ImageUlBulletErrorStyling{
    list-style-type: none;
    margin: 0;
    margin-bottom: 20px;
    margin-top: -30px;
}

.ImageErrorPoints{
    color: red;
    margin-left: -30px;
    margin-bottom: 10px;
}

.Edit-image-inner{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: right;
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
    padding: 50px;
}

.done-edit-container {
    display: flex;
    justify-content: center;
    gap: 50px;
}

#edit-image-input{
    margin-left: 0;
}

.done-edit-bttn {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #348fdc;
    padding: .5rem 1rem;
    margin-top: .5rem;
    border-radius: .3rem;
    border: none;
    font-size: 1rem;
    width: 9rem;
    height: 2rem;
}

.done-edit-bttn:hover {
    opacity: .8;
    cursor: pointer;
    transform: scale(1.1);
}

.done-edit-bttn:active {
    transform: scale(.98);
}

#done-edit-cancel-bttn {
    background-color: #979ea2;
}
