a i {
  color: white;
  margin-right: 2rem;
}

.logout-button {
  margin-right: 2rem;
  margin-bottom: 7px;
}

.notsignedin-SB {
  flex: 1;
  width: 30rem;
  height: 35px;
  border-radius: 3px;
  border: solid white 0;
  /* opacity: 0.8; */

}

.search-barNav {
  flex: 1;
  width: 50rem;
  height: 35px;
  border-radius: 3px;
  border: solid white 0;
  opacity: 0.8;
}

.search-barNav::placeholder {
  padding-left: 3px;
}

.NavBarRightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: fit-content;
}

/* Search bar Container */

.SearchBarContainer {
  position: absolute;
  height: fit-content;
  width: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255);
  top: 64px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
}
.SearchBarContainerLoggedOut {
  position: absolute;
  height: fit-content;
  width: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.485);
  top: 64px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.HiddenResult {
  display: none;
}

.Filteredimages-container {
  display: flex;
  justify-content: right;
  padding: 8px;
  width: 100%;
  height: fit-content;
}

.Filtereduser-container {
  display: flex;
  padding: 5px;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid black;
}

.FilteredUserReturnContainer {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  height: fit-content;
  width: fit-content;
  width: 100%;
  padding-bottom: 10px;
}

.FilteredreturnContainer {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  height: fit-content;
  width: fit-content;
  /* border-bottom: 1px solid black; */
  width: 100%;
  padding-bottom: 10px;
}

.SearchImageMappedContainer {
  height: 120px;
  width: 120px;
}

.SearchImageIndividual {
  height: 100px;
  width: 120px;
}

.SearchUserImageIndividual {
  height: 100px;
  width: 120px;
  border-radius: 100px;
}

.SearchImageNavLinkTitle {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  color: black;
  font-weight: 100;
  bottom: 5px;
  height: 17px;

}

.errorHandlingSearchContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid black;
}

.errorhandlingSearchmessage {
  color: red;
  padding: 8px;
  font-size: 18px;
}

.search-barNav {
  flex: 1;
  width: 50rem;
  height: 35px;
  border-radius: 3px;
  border: solid white 0;
  opacity: 0.8;
}

.search-barNav::placeholder {
  padding-left: 3px;
}



.SearchUserMappedContainer {
  height: 120px;
  width: 120px;
}

.SearchImageIndividual {
  height: 100px;
  width: 120px;
}

.SearchImageNavLinkTitle {
  color: black;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  bottom: 5px;
  height: 17px;
}


.toggleResultsSearch {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #348fdc;
  padding: .5rem 1rem;
  margin-left: .5rem;
  margin-top: .5rem;
  margin-bottom: .2rem;

  border-radius: .3rem;
  border: none;
  font-size: 1rem;
  width: fit-content;
}
.toggleResultsSearch:hover {
  cursor: pointer;
}


.toggleResultsInactiveSearch {
  display: flex;
  justify-content: center;
  color: rgb(7, 7, 7);
  padding: .5rem 1rem;
  margin-left: .5rem;
  margin-top: .5rem;
  margin-bottom: .2rem;

  border-radius: .3rem;
  border: none;
  font-size: 1rem;
  width: fit-content;
}
.toggleResultsInactiveSearch:hover {
  cursor: pointer;
}
.FilteredreturnContainerDemo {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  padding-left: 5px;
  height: fit-content;
  width: fit-content;
  border-bottom: 1px solid black;
  width: 100%;
}
