#modal {
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  /* background-color: black;
  height: 100%; */
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: .01;
}
#modal-background2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: .4;
}

#modal-content {
  position: absolute;
  background-color: white;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
}
