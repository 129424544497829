/* TODO Add site wide styles */


* {
    font-family: Proxima Nova,helvetica neue,helvetica,arial,sans-serif;
}


html, body {
    height: 100%;
    width: 100%;
    margin: 0px;
    min-width: 1300px;
    min-height: 1000px;
}

#root {
    width: 100%;
    height: 100%;
    min-width: 1300px;
    min-height: 1000px;
    margin: none;
}
