.nav-explore {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    align-items: center;
    font-size: 22px;
    background-color: #202023;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 64px;
    width: 100%;
}

.nav-explore-userless {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
    align-items: center;
    font-size: 22px;
    background-color: #202023;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 64px;
    width: 100%;
}

a i {
    color: white;
    margin-right: 2rem;
}

.logout-button {
    margin-right: 2rem;
    margin-bottom: 7px;
}
.NavBarRightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    /* width: fit-content; */
    margin-left: -5px;
    column-gap: 20px;
    margin-right:20px;

}

.navbar-explore-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.explore-bttn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;
}

.upload-icon {
    width: 35px;
    height: 35px;
    margin-right: 26px;
    /* border-radius: 25px; */
}

.upload-icon:hover {
    opacity: .8;
    cursor: pointer;
}

#about-bttn{
    display: flex;
    font-size: 16px;
    width: 50px;
}

.ExploreButtonNav {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-bottom: 1px;
    width: 80px;
}

.ExploreButtonNav:hover {
    opacity: .8;
}

.NavBarLeftSide {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;
    margin-left: -5px;
}

.loggedInNav{
    display: flex;
width: 100%;
justify-content: flex-end;
}
.loggedOutSearchNav{
    display: flex;
width: 100%;
justify-content: center;
}

#you-link{
    margin-right: -60px;
}

#about-us-link{
    margin-left: -30px;
}
