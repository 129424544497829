img.profile-icon {
  width: 35px;
  height: 35px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 25px;

}

ul.profile-list {
  list-style-type: none;
  
}

.profile-list-item logout {
  display: flex;
  justify-content: center;
  color: black;
  text-decoration: none;
  padding-top: 2px;
  margin-bottom: 10px;

}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: absolute;
  width: 11.5em;
  height: 7.0em;
  background-color: white;
  border: 1px solid black;
  right: 3.5%;
  padding: 1rem;
  border-radius: .3rem;
}

.logout-li {
  display: flex;

  padding-top: 10px;
  margin-top: 20px;
  font-size: 16px;
  border-top: 1px solid rgb(235, 228, 228);

}


.profile-list-item {
  font-size: 16px;
  text-align: center;
  margin-left: -48px;
}

.user-name-li {
  font-size: 16px;
  font-weight: 450;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(235, 228, 228);
}


.hover-link {
  padding-left: 3px;
  cursor: pointer;
  /* padding: 2px; */
}

.hover-link:hover {
  background-color: rgb(216, 207, 207);
  margin-bottom: 20px;
  padding-bottom: 5px;

  width: 100%;
  opacity: 0.8;
}

.profile-icon:hover {
  cursor: pointer;
  opacity: .9;
}

.profile-page-reroute {

  text-decoration: none;
  color: black;
  padding-bottom: 5px;
  padding-top: 5px;

}

.profile-page-reroute:hover {
  text-decoration: underline;
  color: black;
}

.profile-page-reroute-upload{
  color: rgb(100, 100, 182);


}
 .profile-page-reroute-upload:hover #drop-nav-link{
    text-decoration: underline;
    color: rgb(100, 100, 182);
  
  }
   .upload-photo-dropdown{
    margin-top:20px;
  }
  .numOfImages {
    padding-bottom: 10px;
    margin-top: 20px;
   
    font-size: 14px;
    color: black;
    margin-left: -80px;
    margin-top: 20px;
    padding-left: 70px;
  
    
  }



.profile-page-reroute-upload:hover {
  text-decoration: underline;
  color: rgb(100, 100, 182);

}

.upload-photo-dropdown {
  margin-top: 20px;
}

.numOfImages {
  font-size: 14px;
  color: black;
  margin-left: -69px;
  margin-top: 20px;


  /* margin-left:-0.2px; */
  padding-left: 70px;

}

.circle-nav {
  width: 65px;
  height: 65px;
  padding-right: 5px;
  margin-top: -30px;
}

.three-item-container {
  display:flex;
  flex-direction: column;
  align-items: left;
}
