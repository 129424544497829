/* body {
    background: url(https://images.unsplash.com/photo-1541417904950-b855846fe074?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1141&q=80);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
} */

body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.login-containerPNF {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-top: 6rem;
}

.inner-loginPNF {
    display: flex;
    align-items: center;
    padding: 20px;

    border: solid white;
    border-radius: .3rem;
    justify-items: center;
    background-color: white;
}

#login-bannerPNF {
    display: grid;
    justify-content: space-around;
}

#circles-containerPNF {
    display: flex;
    justify-content: center;

}
#circlesPNF {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: .8rem;
    padding-bottom: .7rem;
}

#to-klickr1 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    margin: .5rem 0;
}

.linkerror {
    font-size: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1em;
    align-items: center;
    text-align: center;
}
.linkerrorText {
    color: black;
    font-style: italic;
}

.linkerror:hover {
    background-color: rgba(255, 255, 0, 0.474);
}
.linkerrorText:hover {
    font-weight: bold;
}
